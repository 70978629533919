import '../sass/main.scss';

import $ from 'jquery';
import '@fancyapps/fancybox';
import 'bootstrap/dist/js/bootstrap.bundle';

$(function() {
    $('[data-fancybox]').fancybox();

    $('.flash__close').on('click', function() {
        let $flash = $(this).parents('.flash');
        $flash.fadeOut(200, function() {
            $flash.remove();
        });

        return false;
    });
});